import { Router, getCurrentUrl } from "preact-router";
import { useEffect, useState } from "preact/hooks";

// Code-splitting is automated for `routes` directory
import Home from "../routes/home";

const App = () => {
  const [page, setPage] = useState(getCurrentUrl());

  useEffect(() => {
    setPage(getCurrentUrl())

    console.log(page)
  }, [])

  return (
    <div class="w-screen h-screen bg-zinc-950 flex justify-center" id="app">
      <div class="max-w-screen-lg mx-auto">
        <main>
          <Router>
            <Home path="/" />
          </Router>
        </main>
      </div>
    </div>
  );
};

export default App;
